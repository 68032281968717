import { render, staticRenderFns } from "./ServiceHourForm.vue?vue&type=template&id=2b17b5c4&scoped=true&"
import script from "./ServiceHourForm.vue?vue&type=script&lang=js&"
export * from "./ServiceHourForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b17b5c4",
  null
  
)

export default component.exports